import { FormData } from '@netsyde/react-forms'
import { Application, Enrolment, User, findMaxBy, ValidationResult, getEmptyEnrolmentData } from 'portal2-models';
import { UserData } from '../FrontEndTypes';
const NETWORK_DELAY_MS = 300

const enrolments: Array<FormData<Enrolment>> = []
const applications: Array<FormData<Application>> = []

export const mockUser: User = {
	userId: 1,
	firstName: 'Jon',
	lastName: 'Doe',
	email: 'jon.doe@abcfarms.ca', 
	phoneNumber: 555_123_4567_98765,
	languageId: 1
}

export const mockUserData: UserData = {
	enrolments, 
	applications, 
	user: mockUser
}

function initApplication(app: FormData<Application>) {
	app.applicationId ??= findMaxBy(applications, a => a.applicationId, 0) + 1
	app.creationDate ??= new Date()
}

function initEnrolment(op: FormData<Enrolment>) {
	op.operationId ??= findMaxBy(enrolments, o => o.operationId, 0) + 1
	op.creationDate ??= new Date()
}

function updateStore<EntityT>(store: Array<EntityT>, entity: EntityT, keySelector: (ent: EntityT) => number | undefined) {
	let existingIndex = store.findIndex(existingEntity => keySelector(existingEntity) === keySelector(entity))

	if (existingIndex >= 0) store.splice(existingIndex, 1, entity)
	else store.push(entity)
}

export async function saveApplication(application: FormData<Application>, doSubmit = false): Promise<ValidationResult<FormData<Application>>> {
	await networkDelay(NETWORK_DELAY_MS)

	const app = Object.assign({}, application)

	initApplication(app)

	// this marks submission
	if (doSubmit) app.submissionDate ??= new Date()

	updateStore(applications, app, a => a.applicationId)

	const result: ValidationResult<FormData<Application>> = {
		isValid: true,
		model: app,
		validationErrors: []
	}

	return result
}

export async function deleteApplication(id: number): Promise<void> {
	await networkDelay(NETWORK_DELAY_MS)

	const index = applications.findIndex(a => a.applicationId === id)

	applications.splice(index, 1)
}

export async function createEnrolment(): Promise<FormData<Enrolment>> {
	await networkDelay(NETWORK_DELAY_MS)

	const op = getEmptyEnrolmentData(mockUser)

	initEnrolment(op)

	// this marks submission
	enrolments.push(op)

	return op
}

export async function saveEnrolment(enrolment: FormData<Enrolment>, doSubmit = false): Promise<ValidationResult<FormData<Enrolment>>> {
	await networkDelay(NETWORK_DELAY_MS)

	const op = Object.assign({}, enrolment)

	initEnrolment(op)

	// this marks submission
	if (doSubmit) op.submissionDate ??= new Date()

	updateStore(enrolments, op, o => o.operationId)

	const result: ValidationResult<FormData<Enrolment>> = {
		isValid: true,
		model: op,
		validationErrors: []
	}

	return result
}

export async function deleteEnrolment(id: number): Promise<void> {
	await networkDelay(NETWORK_DELAY_MS)

	const index = enrolments.findIndex(e => e.operationId === id)

	enrolments.splice(index, 1)
}

async function networkDelay(ms: number) {
	return new Promise((res) => {
		setTimeout(res, ms)
	})
}

export const mockApi = {
	saveApplication, 
	saveEnrolment, 
	createEnrolment,
	deleteApplication, 
	deleteEnrolment
}
