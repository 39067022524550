import React , {MouseEventHandler} from 'react'


export interface PanelProps {
    title: string;
    children: string;
    isActive: boolean;
    onShow: MouseEventHandler<HTMLParagraphElement>;
    onHide: MouseEventHandler<HTMLParagraphElement>;
}

export const Panel = ({title , children, isActive, onShow, onHide}: PanelProps) =>
    <>
        <section className="panel">
            <div className="toggleContainer" onClick={ isActive ? onHide : onShow }>
                <h4 dangerouslySetInnerHTML={{__html: title}} ></h4> 
                { isActive? ( 
                    <p className="minus">-</p>):(
                    <p className="plus">+</p>)
                }
            </div>
        </section>
        
        {isActive ? (
            <p className="content" dangerouslySetInnerHTML={{__html: children}}></p>
        ):(
            <p></p> //empty 
        )}
    </>
    ;