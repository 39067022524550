import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom'
import { Language, Constants } from 'portal2-models'

import { ExternalRoutes, UserData } from './FrontEndTypes'
import { routes } from './routes'
import ErrorPage from './pages/ErrorPage'
import DashboardListPage from './pages/DashboardPage'
import EnrolmentEditPage from './pages/EnrolmentEditPage'
import ApplicationEditPage from './pages/ApplicationEditPage'
import HelpPage from './pages/HelpPage'

import LoadingOverlay from './components/LoadingOverlay'
import useApi, { Api, ApiHook } from './hooks/useApi'
import { Layout } from './pages/Layout'

import './FrontEnd.scss'

// these routes can be mounted in any application router as demonstrated in App.tsx
// and/or mounted in a sub-route of a larger application (as will be the case with the cost-share app)

export interface FrontEndBase {
	language: Language
	constants: Constants | undefined
	userData: UserData | undefined
}

export interface FrontEndProps extends FrontEndBase {
	onLanguageChange: (language: Language) => void
	externalRoutes: ExternalRoutes
	api: Api
}

export interface FrontEndContextType extends FrontEndBase {
	apiHook: ApiHook
}

export const FrontEnd: React.FC<FrontEndProps> = (props) => {
	const { language, constants, onLanguageChange, externalRoutes, userData, api } = props
	const apiHook = useApi(api)

	const context: FrontEndContextType = { language, constants, userData, apiHook }

	if (!constants || !userData) return <LoadingOverlay language={language} isLoading />

	const loadSpinner = apiHook.isLoading ? <LoadingOverlay language={language} isLoading /> : null

	const routeObjects: Array<RouteObject> = [
		{
			path: routes.dashboard,
			element: <Layout language={language} onLanguageChange={onLanguageChange} externalRoutes={externalRoutes} user={userData.user} />,
			errorElement: <ErrorPage language={language} />,
			children: [
				{
					path: routes.dashboard,
					element: <DashboardListPage context={context} dashTab='enrolments' />
				},
				{
					path: routes.enrolment.list,
					element: <DashboardListPage context={context} dashTab='enrolments' />
				},
				{
					path: routes.application.list,
					element: <DashboardListPage context={context} dashTab='applications' />
				},
				{
					path: routes.program.list,
					element: <DashboardListPage context={context} dashTab='programs' />
				},
				{
					path: routes.help,
					element: <HelpPage language={language} />
				},
				{
					path: routes.enrolment.edit(),
					element: <EnrolmentEditPage context={context} />
				},
				{
					path: routes.enrolment.view(),
					element: <EnrolmentEditPage context={context} isReadonly />
				},
				{
					path: routes.application.new(),
					element: <ApplicationEditPage context={context} isNew />
				},
				{
					path: routes.application.edit(),
					element: <ApplicationEditPage context={context} />
				},
				{
					path: routes.application.view(),
					element: <ApplicationEditPage context={context} isReadonly />
				},
			]
		},
	]

	const router = createBrowserRouter(routeObjects)

	return (
	<div className='front-end'>
		<RouterProvider router={router} />
		{loadSpinner}
	</div>
	)
}

export default FrontEnd
