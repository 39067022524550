const en = {
	loading: 'Loading', 
	oops: 'Oops', 
	unexpectedErrorOccurred: "Sorry, an unexpected error has occurred", 
	submit: 'Submit', 
	save: 'Save', 
	cancel: 'Cancel', 
	dashboard: 'Dashboard', 
	backToDashboard: 'Back to Dashboard', 
	confirmDeleteApplication: (applicationId: number) => `Are you sure you want to delete application ${applicationId}?`, 
	confirmDeleteEnrolment: (operationId: number) => `Are you sure you want to delete enrolment ${operationId}?`, 
	pleaseCompleteEnrolment:  'Please complete the enrolment before submission', 
	help: 'Help',
	login: 'Login', 
	logout: 'Logout',
	myProfile: 'My Profile',
	costShare: 'Cost-share',
	osciaDeliveredCostSharePrograms: 'OSCIA-Delivered Farmer Cost-share Programs',
	footer1Header: "Interested in learning more about OSCIA-Delivered Farmer Cost-share and Education Programs?",
	footer1Button:"CHECK OUT OUT PROGRAMS",
	footer2Header:"Get information about program requirements, available cost-share and eligibility!",
	footer2Button:"VISIT OUR ONLINE PROGRAM GUIDE",
	footer3Header:"For any assistance with the OSCIA portal, please contact us at <a href='tel:5198410680'>(519) 841 0680</a> or <a href='mailto:portalsupport@ontariosoilcrop.org'>portalsupport@ontariosoilcrop.org</a>",
	footer3Button:"FREQUENTLY ASKED QUESTIONS",
	portal: 'Portal',
	programGuideLink: 'https://programguides.ontariosoilcrop.org/',
	costShareProgramsLink: 'https://www.ontariosoilcrop.org/cost-share-programs/',
}

export type LocalizedResources = typeof en

const fr: LocalizedResources = {
	loading: 'Chargement en cours', 
	oops: 'Oops', 
	unexpectedErrorOccurred: "Désolé, une erreur inattendue s'est produite", 
	submit: 'Soumettre', 
	save: 'Sauvegarder', 
	cancel: 'annuler', 
	dashboard: 'Tableau de bord', 
	backToDashboard: 'Retour au tableau de bord', 
	confirmDeleteApplication: (applicationId: number) => `Êtes-vous sûr de vouloir supprimer l'application ${applicationId}?`, 
	confirmDeleteEnrolment: (operationId: number) => `Êtes-vous sûr de vouloir supprimer l'inscription ${operationId}?`, 
	pleaseCompleteEnrolment:  "Veuillez compléter l'inscription avant de soumettre", 
	help: 'Aide',
	login: 'Connect-vous', 
	logout: 'Déconnecter',
	myProfile: 'Mon Profil',
	costShare: 'Partage des coûts',
	osciaDeliveredCostSharePrograms: "Programmes de partage des coûts offerts par l'OSCIA",
	footer1Header: "Voulez-vous en savoir plus sur les programmes d’aide financière à frais partagés administrés par l’AASRO à l’intention des agriculteurs?",
	footer1Button:"CONSULTEZ NOS PROGRAMMES",
	footer2Header:"Renseignez-vous sur les exigences, l’aide financière à frais partagés offerte et les critères d’admissibilité du programme!",
	footer2Button:"CONSULTEX LE GUIDE DU PROGRAMME EN LINGE",
	footer3Header:"Pour obtenir de l'aide ou pour signaler un problème avec osciaportal.org, communiquez avec nous <a href='tel:5198410680'>(519) 841 0680</a> or <a href='mailto:portalsupport@ontariosoilcrop.org'>portalsupport@ontariosoilcrop.org</a>",
	footer3Button:"VOS QUESTIONS LES PLUS FRÉQUENTES",
	portal: 'Portail',
	programGuideLink: 'https://programguides.ontariosoilcrop.org/fr/',
	costShareProgramsLink: 'https://www.ontariosoilcrop.org/fr/programmes-daide-financiere-a-frais-partages-2/',
}

export const languageResources = { en, fr }