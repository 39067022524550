import React, { useState } from 'react'
import { Params, useNavigate, useParams } from 'react-router-dom'

import { FormData, useFormBuilder } from '@netsyde/react-forms'
import { assertNever, Enrolment, ValidationError, ValidationResult } from 'portal2-models'
import { EnrolmentForm, EnrolmentFormCommandHandler, createEnrolmentFormDefinition } from 'portal2-enrolment'

import { routes } from '../routes'
import { EntityPageProps, EntityParams, UserData } from '../FrontEndTypes'

import './EnrolmentEditPage.scss'

const loadEnrolment = (data: UserData, urlParams: Params<EntityParams>) => {
	const operationId = Number(urlParams.entityId)

	let enrolment = data.enrolments.find(e => e.operationId === operationId) 

	if (!enrolment) throw new Error(`Enrolment with operationId ${operationId} not found`)

	return enrolment
}

export const EnrolmentEditPage: React.FC<EntityPageProps> = ({ context, isReadonly }) => {
	const { language, apiHook, constants } = context
	const [validationErrors, setValidationErrors] = useState<ValidationError<FormData<Enrolment>>[]>([])

	let params = useParams<EntityParams>()

	let entity = loadEnrolment(context.userData!, params)

	const navigate = useNavigate()
	let formBuilder = useFormBuilder(createEnrolmentFormDefinition(constants!), { language, isDisabled: apiHook.isLoading || !entity, isReadonly }, entity)

	// this is a hack to avoid issue with multiple rerenders 
	// TODO: investigate
	React.useEffect(() => {
		if (entity && (Object.keys(formBuilder.formData).length === 0)) formBuilder.setData(entity)
		formBuilder.setDisabled(apiHook.isLoading || !entity)
		formBuilder.setLanguage(language)
	}, [formBuilder, entity, apiHook.isLoading, language])

	React.useEffect(() => {
		validationErrors.forEach(ve => {
			formBuilder.addExternalError(ve.field, ve.value, ve.errorMessage)
		})
	}, [formBuilder, validationErrors])

	const handleCommand: EnrolmentFormCommandHandler = async (command) => {
		let validationResult: ValidationResult<FormData<Enrolment>>

		switch (command) {

			case 'save': 
				validationResult = await apiHook.api.saveEnrolment(formBuilder.formData, false)

				if (validationResult.isValid) navigate(routes.dashboard)
				else {
					const newValidationErrors = validationErrors.concat(validationResult.validationErrors)
					setValidationErrors(newValidationErrors)
				}
				break

			case 'submit':
				validationResult = await apiHook.api.saveEnrolment(formBuilder.formData, true)

				if (validationResult.isValid) navigate(routes.dashboard)
				else {
					const newValidationErrors = validationErrors.concat(validationResult.validationErrors)
					setValidationErrors(newValidationErrors)
				}
				break

			case 'cancel':
				// TODO: possibly add "are you sure" message here if changes present
				navigate(routes.dashboard)
				break
			
			default: assertNever(command)
		}
	}

	return <EnrolmentForm formBuilder={formBuilder} onCommand={handleCommand} />
}

export default EnrolmentEditPage
