import { Outlet } from 'react-router-dom'
import { Header, HeaderProps } from '../components/Header'
import { AppContainer } from 'portal2-components'
import RainbowFooter from '../components/RainbowFooter'

import './Layout.scss'

export const Layout: React.FC<HeaderProps> = (props) => {

	return (
		<div className='front-end layout'>
			<Header {...props}/>
			<AppContainer>
				<Outlet />
			</AppContainer>
			<RainbowFooter language={props.language}></RainbowFooter>
		</div>
	)
}