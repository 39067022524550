import React from 'react'
import { Params, useNavigate, useParams, useSearchParams } from 'react-router-dom'
import { useFormBuilder } from '@netsyde/react-forms'

import { ApplicationType, Intake, NewApplicationSeed, assertNever, getEmptyApplicationData } from 'portal2-models'
import { ApplicationFormSelector, createApplicationFormDefinition } from 'portal2-application'

import { routes, applicationQueryParams } from '../routes'
import { languageResources } from '../languageResources'
import { EntityPageProps, EntityParams, UserData } from '../FrontEndTypes'
import { ApplicationFormCommandHandler } from 'portal2-application'

const loadApplication = (data: UserData, urlParams: Params<EntityParams>, searchParams: URLSearchParams, isNew: boolean, applicationTypes: Array<ApplicationType>, intakes: Array<Intake>) => {
	const applicationId = Number(urlParams.entityId)

	let application = data.applications.find(a => a.applicationId === applicationId) 

	const applicationTypeId = application?.applicationTypeId ?? Number(searchParams.get(applicationQueryParams.applicationTypeId))

	const applicationType = applicationTypes.find(at => at.applicationTypeId === applicationTypeId)

	if (!applicationType) 
		throw new Error(`Application type ID ${applicationTypeId} is unknown`)

	const intake = intakes.find(i => i.applicationTypeId === applicationTypeId)

	if (!intake) 
		throw new Error(`No intake available for application type ID ${applicationTypeId}`)

	if (isNew) {
		const operationId = Number(searchParams.get(applicationQueryParams.operationId))

		let enrolment = data.enrolments.find(o => o.operationId === operationId)
		let appSeed: NewApplicationSeed = {
			operationId: enrolment!.operationId!,
			applicationTypeId,
			intakeId: intake!.intakeId,
		}

		if (enrolment && applicationType) {
			application = getEmptyApplicationData(appSeed, enrolment)
		}
	}

	return application
}

export const ApplicationEditPage: React.FC<EntityPageProps> = ({ isNew, isReadonly, context }) => {
	const { language, constants, apiHook } = context

	const localizedResources = languageResources[language]

	let urlParams = useParams<EntityParams>()
	let [searchParams,] = useSearchParams()

	const application = loadApplication(context.userData!, urlParams, searchParams, !!isNew, constants?.hierarchy.applicationTypes!, constants?.hierarchy.intakes!)

	const navigate = useNavigate()
	const formBuilder = useFormBuilder(createApplicationFormDefinition(constants!, application?.applicationTypeId!), { language, isDisabled: apiHook.isLoading || !application, isReadonly }, application)

	// this is a hack to avoid issue with multiple rerenders 
	// TODO: investigate
	React.useEffect(() => {
		if (application && (Object.keys(formBuilder.formData).length === 0)) formBuilder.setData(application)
		formBuilder.setDisabled(apiHook.isLoading || !application)
		formBuilder.setLanguage(language)
	}, [formBuilder, application, apiHook.isLoading, language])

	const handleCommand: ApplicationFormCommandHandler = async (command) => {
		switch (command) {
			case 'cancel':
				navigate(routes.application.list)
				break
			case 'save':
				// TODO: add error catches
				await apiHook.api.saveApplication(formBuilder.formData)
				navigate(routes.application.list)
				break
			case 'submit':
				formBuilder.validate()
				if (formBuilder.isValid) {
					// TODO: add error catches
					await apiHook.api.saveApplication(formBuilder.formData, true)

					navigate(routes.application.list)
				}
				else alert(localizedResources.pleaseCompleteEnrolment)
				break
			default: assertNever(command)

		}
	}

	return <ApplicationFormSelector 
				formBuilder={formBuilder} 
				applicationTypeId={application?.applicationTypeId!} 
				onCommand={handleCommand}
			/>
}

export default ApplicationEditPage
