import { Language, User } from 'portal2-models'
import { languageResources } from '../languageResources'
import { ExternalRoutes } from '../FrontEndTypes'
import { routes } from '../routes'
import { Link } from 'react-router-dom'

import './Header.scss'

import logoImage from './logo.svg'

export interface HeaderProps {
	language: Language
	externalRoutes: ExternalRoutes
	onLanguageChange: (language: Language) => void
	user?: User
}

export const Header: React.FC<HeaderProps> = (props) => {
	const localized = languageResources[props.language]

	return (
		<header>
			
			<div className="flex-container justify-content-center">
				<nav className="navbar navbar-expand-sm navbar-toggleable-sm navbar-light bg-white border-bottom box-shadow">
					<div className="header_logo container">
						<Link to={routes.dashboard}>
							<img className="logo-img d-flex" src={logoImage} alt="logo_img" />
						</Link>
						<div className="barr">
							<Link to={routes.dashboard} className='navbar-brand d-xl-none'>
								{localized.costShare}
							</Link>
							<Link to={routes.dashboard} className='navbar-brand d-none d-xl-inline'>
								{localized.osciaDeliveredCostSharePrograms}
							</Link>
						</div>
						<button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target=".navbar-collapse"
							aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
							<span className="navbar-toggler-icon"></span>
						</button>
						<div className="navbar-collapse collapse d-sm-inline-flex justify-content-between">
							<ul className="navbar-nav flex-grow-1">
							</ul>
							<LoginNavItems {...props} />
						</div>
					</div>
				</nav>
				<div className="@environmentInfo.EnvironmentName environment-info">
					<a href='/possibly/add/config/page'>Testing Config Display</a>
				</div>
			</div>
		</header>
	)
}

export default Header


const LoginNavItems: React.FC<HeaderProps> = ({ language, user, externalRoutes, onLanguageChange }) => {
	const localized = languageResources[language]

	const profileDropdown = !user ? null : (
		<li className="nav-item dropdown">
			<button className="nav-link dropdown-toggle" data-bs-toggle="dropdown">
				{`${user.firstName} ${user.lastName}`}
			</button>
			<ul className="dropdown-menu">
				<li>
					<a className="dropdown-item" href={externalRoutes.portal}>{localized.portal}</a>
				</li>
				<li>
					<a className="dropdown-item" href={externalRoutes.profile}>{localized.myProfile}</a>
				</li>
				<li>
					<hr className="dropdown-divider" />
				</li>
				<li>
					<a className="dropdown-item" href={externalRoutes.logout}>{localized.logout}</a>
				</li>
			</ul>
		</li>
	)

	const logOpLink = !user ?
		<li className="nav-item d-none d-md-inline">
			<a className="btn btn-success btn-sm help" href={externalRoutes.login}>{localized.login}</a>
		</li> : 
		<li className="nav-item d-none d-md-inline">
			<a className="btn btn-success btn-sm help" href={externalRoutes.logout}>{localized.logout}</a>
		</li>

	const getLanguageLinkClass = (linkLanguage: Language) => {
		return `nav-link text-dark ${linkLanguage === language ? 'selected-language' : ''}`
	}

	const getLanguageChangeHandler = (linkLanguage: Language) => {
		const handler: React.MouseEventHandler<HTMLButtonElement> = (e) => {
			onLanguageChange(linkLanguage)
			e.preventDefault()
		}

		return handler
	}

	return (
		<>
			<ul className="navbar-nav">
				{profileDropdown}
			</ul>
			<ul className="navbar-nav">
				<li className="nav-item">
					<button className={getLanguageLinkClass('en')} onClick={getLanguageChangeHandler('en')}>English</button>
				</li>
				|
				<li className="nav-item">
					<button className={getLanguageLinkClass('fr')} onClick={getLanguageChangeHandler('fr')}>Français</button>
				</li >

				<li className="nav-item">
					<form className="d-flex">
						<Link className='btn btn-success btn-sm help' to={routes.help} target='_blank'>{localized.help}</Link>
					</form>
				</li>
				{logOpLink}
			</ul >
		</>
	)
}