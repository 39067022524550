import React, { useState } from 'react'
import { LocalizedProps } from '../FrontEndTypes'
import { HelpSections } from './HelpPageContent'
import { languageResources } from '../languageResources'
import { Panel } from "./Panel"

import './HelpPage.scss'

export const HelpPage: React.FC<LocalizedProps> = ({ language }) => {
	const [activeIndex, setActiveIndex] = useState(-1);
	const helpSections = HelpSections.map((faq, ix) => {
		return (
			<div key={ix} className='faq'>
					<Panel
					title={ ix + 1 + ". "+faq[language].header }
					isActive={ activeIndex === ix }
					onShow={ () => {setActiveIndex(ix); } }
					onHide={ () => {setActiveIndex(-1); } }
					>
					{ faq[language].content }
					</Panel>
			</div>
		)
	})

	return (
		<div className='help page'>
			<h1>{languageResources[language].help}</h1>
			{helpSections}
		</div>
	)

}

export default HelpPage