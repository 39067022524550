import { Language } from 'portal2-models'

export interface Faq {
	header: string
	content: string
}

export type LocalizedFaq = Record<Language, Faq>

export const HelpSections: Array<LocalizedFaq> = [
	{
		en: {
			header: 'Where can I find information about OSCIA-delivered workshops and programs?',
			content: "If you would like more information about the workshops and programs delivered by OSCIA, visit <a href='http://ontariosoilcrop.org/' target='_blank'>ontariosoilcrop.org</a>.", 
		},
		fr: {
			header: 'Quel est la question Un?',
			content: 'Réponse Un',
		},
	}, 
	{
		en: {
			header: 'Which web browser should I use to access osciaportal.org?',
			content: "<p>osciaportal.org can be used on any modern internet browser. Good options are Google Chrome, Mozilla Firefox, Apple Safari and Microsoft Edge.</p><p>For the best experience on our site, ensure you are using the most current version of your chosen browser.</p>", 
		},
		fr: {
			header: 'Quel est la <b>question Deux?</b>',
			content: 'Réponse <i>Deux</i>',
		},
	},
	{
		en:{
			header:"Why am I not getting confirmation emails from osciaportal.org after I create an account?",
			content:"<p>There are a few common reasons why you may not have received the confirmation email:</p><ul><li>The confirmation email may have been delivered to your Junk or Spam folder. Check your Junk or Spam folder to see if the confirmation email from <b>no-reply@ontariosoilcrop.org</b> has been incorrectly marked as junk. If the confirmation email landed in your Junk or Spam folder, you will need to move the email to your inbox before the confirmation link can be clicked on.</li><li>The email address that you used to create your account is invalid. In creating a user account, ensure that your information is accurate and valid. You can request for the confirmation email to be resent to you by going to <a href='http://osciaportal.org/' target='_blank'>osciaportal.org</a>, and entering in your email and password you used to create the account,  under the Log In window. If your account has not already been confirmed, you will be given the option to resend the confirmation email.</li></ul><p>To ensure you receive a confirmation email, add <b>no-reply@ontariosoilcrop.org</b> to your acceptable/safe senders list.</p><p>After following the above steps, if you still do not receive a confirmation email, contact us at <a href='mailto:portalsupport@ontariosoilcrop.org'>portalsupport@ontariosoilcrop.org</a> for further assistance.</p>"
		},
		fr:{
			header:"",
			content:""
		}
	},
	{
		en:{
			header:"Can I re-use my login credentials from OntarioPrograms.net for an account set up for the Canadian Agricultural Partnership or Growing Forward 2?",
			content:"No, information you previously provided and/or user accounts set up in ontarioprograms.net specific to the Canadian Agricultural Partnership or Growing Forward 2 programs or workshops, are not transferred to osciaportal.org. If you have not created an account on osciaportal.org, you will need to start by creating a new account."
		},
		fr:{
			header:"",
			content:""
		}
	}
]

export default HelpSections