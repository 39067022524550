import React from 'react'
import { useNavigate } from 'react-router-dom'
import { EntityCommandHandler, ApplicationCommand, EnrolmentCommand, DashboardProps, DashboardTab, Dashboard, DashboardNavHandler } from 'portal2-dashboard'

import { languageResources } from '../languageResources'
import { routes } from '../routes'
import { useConfirmModal } from 'portal2-components'
import { assertNever } from 'portal2-models'
import { FrontEndPageProps } from '../FrontEndTypes'
// import { useRenderCount } from '../hooks/useRenderCount'

export interface DashboardPageProps extends FrontEndPageProps {
	dashTab: DashboardTab
}

export const DashboardListPage: React.FC<DashboardPageProps> = ({ context, dashTab }) => {
	const { language, apiHook, userData, constants } = context
	const localizedResources = languageResources[language]

	const navigate = useNavigate()
	const [confirmModal, confirm] = useConfirmModal(language)

	// useRenderCount("Dashboard")

	const handleApplicationCommand: EntityCommandHandler<ApplicationCommand> = (command) => {
		switch (command.commandType) {
			case 'new':
				const { applicationTypeId, operationId } = command.seed
				navigate(routes.application.new(operationId, applicationTypeId))
				break
			case 'view':
				navigate(routes.application.view(command.id))
				break
			case 'edit':
				navigate(routes.application.edit(command.id))
				break
			case 'delete':
				confirm(localizedResources.confirmDeleteApplication(command.id)).then(wasConfirmed => {
					if (wasConfirmed) apiHook.api.deleteApplication(command.id)
				})
				break
			default: assertNever(command)
		}
	}

	const handleEnrolmentCommand: EntityCommandHandler<EnrolmentCommand> = async (command) => {
		switch (command.commandType) {
			case 'new':
				const newEnrolment = await apiHook.api.createEnrolment()
				navigate(routes.enrolment.edit(newEnrolment.operationId))
				break
			case 'view':
				navigate(routes.enrolment.view(command.id))
				break
			case 'edit':
				navigate(routes.enrolment.edit(command.id))
				break
			case 'delete':
				confirm(localizedResources.confirmDeleteEnrolment(command.id)).then(wasConfirmed => {
					if (wasConfirmed) apiHook.api.deleteEnrolment(command.id)
				})
				break
			default: assertNever(command)
		}
	}

	const handleNav: DashboardNavHandler = tab => {
		switch (tab) {
			case 'enrolments':
				navigate(routes.enrolment.list)
				break
			case 'programs':
				navigate(routes.program.list)
				break
			case 'applications':
				navigate(routes.application.list)
				break
			default: assertNever(tab)
		}
	}


	// console.log(`DashboardData missing: ${!dashboardData} isLoading: ${isLoading}`)

	const dashboardProps: DashboardProps = {
		language,
		enrolments: userData?.enrolments || [],
		applications: userData?.applications || [],
		onApplicationCommand: handleApplicationCommand,
		onEnrolmentCommand: handleEnrolmentCommand,
		disabled: apiHook.isLoading || !userData,
		constants: constants!,
		navTab: dashTab,
		onDashboardNav: handleNav
	}

	return (
		<>
			<Dashboard {...dashboardProps} />
			{confirmModal}
		</>
	)
}

export default DashboardListPage