import { Language } from 'portal2-models'
import React from 'react'
import { languageResources } from '../languageResources'

import LoadingSpinnerSvg from './LoadingSpinner.svg'
import './LoadingOverlay.scss'

export interface LoadingOverlayProps {
	language: Language
	isLoading: boolean
}

export const LoadingOverlay: React.FC<LoadingOverlayProps> = ({ language, isLoading }) => {
	if (!isLoading) return null
	else return (
		<div id='loading-page'>
			<h1>{languageResources[language].loading}</h1>
			<img alt={languageResources[language].loading} className='loading-spinner' src={LoadingSpinnerSvg} />
		</div>
	)
}

export default LoadingOverlay
