import React from 'react'
import { Row, Col } from 'reactstrap'
import { routes } from '../routes'
import { Language } from 'portal2-models'
import { languageResources } from '../languageResources'

import './RainbowFooter.scss'

export interface FooterProps {
	language: Language
}

export const RainbowFooter: React.FC<FooterProps> = (props) => {
	const localized = languageResources[props.language]

	return (
		<footer className="rainbowFooter">
        <Row style={{marginRight:'0'}}>
            <Col style={{backgroundColor:"rgba(49, 133, 88, 0.7215686275)"}}>
                <p>{languageResources[props.language].footer1Header}</p>
                <Row>
                    <div>
                        <a target="_blank" href={localized.costShareProgramsLink}>
                            <button>
                                {languageResources[props.language].footer1Button}
                            </button>
                        </a>
                    </div>
                </Row>
            </Col>

            <Col style={{backgroundColor:"rgba(134, 170, 63, 0.7215686275)"}}>
                <p>{languageResources[props.language].footer2Header}</p>
                <Row>
                    <div>
                        <a target="_blank" href={localized.programGuideLink}>
                            <button>
                            {languageResources[props.language].footer2Button}
                            </button>
                        </a>
                    </div>
                </Row>
            </Col>
 
            <Col style={{backgroundColor:"rgba(194, 197, 46, 0.7215686275)"}}>
                <p dangerouslySetInnerHTML={{__html: languageResources[props.language].footer3Header}}></p>             
                <Row>
                    <div>
                        <a target='_blank' href={routes.help}>
                            <button>
                            {languageResources[props.language].footer3Button}
                            </button>
                        </a>
                    </div>
                </Row>
            </Col>
        </Row>
        </footer>
	)
}

export default RainbowFooter