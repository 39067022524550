import React from 'react';
import { mockApi } from './mockApi'

export type Api = typeof mockApi

interface ErrorSetter {
	(error: any): void
}

interface LoadSetter {
	(isLoading: boolean): void
}

export function wrapApiFunction<ReturnT, FuncT extends (...args: any[]) => Promise<ReturnT>>(func: FuncT, setLoading: LoadSetter, onError: ErrorSetter): FuncT {
	return async function (...args: any[]) {
		setLoading(true)
		try {
			const result = await func(...args)
			return result
		}
		catch (err) {
			onError(err)
		}
		finally {
			setLoading(false)
		}
	} as FuncT
}

export function wrapApi(api: Api, setLoading: LoadSetter, onError: ErrorSetter): Api {
	const wrappedApi = Object.entries(api).reduce((wrappedApi, [funcNameString, func]) => {
		const funcName = funcNameString as keyof Api
		wrappedApi[funcName] = wrapApiFunction(func as any, setLoading, onError) as any
		return wrappedApi
	}, {} as Api)

	return wrappedApi
}

export interface ApiHook {
	api: Api
	isLoading: boolean
	clearError: () => void
	error?: any
}

export function useApi(api: Api): ApiHook {
	const [isLoading, setLoading] = React.useState(false)
	const [error, setError] = React.useState<any>()

	const clearError = () => setError(undefined)

	const wrappedApiRef = React.useRef(wrapApi(api, setLoading, setError))

	return { api: wrappedApiRef.current, isLoading, error, clearError }
}

export default useApi