export const newEntityParam = 'new'
export const entityIdParam = 'entityId'

export const applicationQueryParams =  {
	operationId: 'operationId',
	applicationTypeId: 'applicationTypeId',
}

const entityIdParamHolder = `:entityId`

const listEnrolmentsRoute =  `/enrolments`
const editEnrolmentRoute =  `${listEnrolmentsRoute}/${entityIdParamHolder}/edit`
const viewEnrolmentRoute =  `${listEnrolmentsRoute}/${entityIdParamHolder}`

const listApplicationsRoute =  `/applications`
const newApplicationRoute =  `${listApplicationsRoute}/${newEntityParam}`
const editApplicationRoute =  `${listApplicationsRoute}/${entityIdParamHolder}/edit`
const viewApplicationRoute =  `${listApplicationsRoute}/${entityIdParamHolder}`

const listProgramsRoute =  `/programs`

export const routes = {
	dashboard: '/', 
	help: '/help', 
	enrolment: {
		list: listEnrolmentsRoute,
		edit: (operationId?: number) => !operationId ? editEnrolmentRoute : editEnrolmentRoute.replace(entityIdParamHolder, operationId!.toString()), 
		view: (operationId?: number) => !operationId ? viewEnrolmentRoute : viewEnrolmentRoute.replace(entityIdParamHolder, operationId!.toString())
	}, 
	application: {
		list: listApplicationsRoute,
		new: (operationId?: number, applicationTypeId?: number) => operationId ? newApplicationRoute + `?${applicationQueryParams.operationId}=${operationId}&${applicationQueryParams.applicationTypeId}=${applicationTypeId}` : newApplicationRoute, 
		edit: (applicationId?: number) => !applicationId ? editApplicationRoute : editApplicationRoute.replace(entityIdParamHolder, applicationId!.toString()), 
		view: (applicationId?: number) => !applicationId ? viewApplicationRoute : viewApplicationRoute.replace(entityIdParamHolder, applicationId!.toString()), 
	}, 
	program: {
		list: listProgramsRoute,
	}, 
	params: {
		newEntity: newEntityParam, 
		entityId: entityIdParam
	}
}

export default routes
