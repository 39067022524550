import React from 'react'
import { useRouteError } from 'react-router-dom'
import { LocalizedProps } from '../FrontEndTypes'
import { languageResources } from '../languageResources'

export const ErrorPage: React.FC<LocalizedProps> = ({ language }) => {
	const localizedResources = languageResources[language]
	const error = useRouteError() as any

	console.error(error)

	return (
		<div id="error-page">
			<h1>{localizedResources.oops}</h1>
			<p>{localizedResources.unexpectedErrorOccurred}</p>
			<p>
				<i>{error.statusText || error.message}</i>
			</p>
		</div>
	)
}

export default ErrorPage
