import React from 'react'

import { DemoControlPanel } from '@netsyde/react-forms'
import { Language, getFilledEnrolmentData, getFilledApplicationData, NewApplicationSeed, mockConstants /*, User */ } from 'portal2-models'
import { Button } from 'portal2-components'

import { mockApi, mockUserData, mockUser } from './hooks/mockApi'
import { defaultExternalRoutes } from './FrontEndTypes'
import { FrontEnd } from './FrontEnd'

function App() {
	// const [userData, setUserData] = React.useState(mockUserData)

	const [language, setLanguage] = React.useState<Language>('en')

	const [dummyBoolean,  doUpdate] = React.useState(false)

	const addBusiness = async () => {
		const enrolment = getFilledEnrolmentData(mockUser)
		await mockApi.saveEnrolment(enrolment)
		doUpdate(!dummyBoolean)
	}

	const addApplication = async () => {

		const enrolment = mockUserData.enrolments.find(e => e.submissionDate)

		if (enrolment) {

			const applicationType = mockConstants.hierarchy.applicationTypes.find(a => a.applicationTypeName.toLowerCase().indexOf('ofcaf') >= 0)
			const intake = mockConstants.hierarchy.intakes.find(i => i.applicationTypeId === applicationType!.applicationTypeId)

			const appSeed: NewApplicationSeed = {
				operationId: enrolment.operationId!,
				applicationTypeId: applicationType!.applicationTypeId,
				intakeId: intake!.intakeId,
			}

			const app = getFilledApplicationData(appSeed, enrolment)
			await mockApi.saveApplication(app)
			doUpdate(!dummyBoolean)
			alert(`Application created for enrolment ${enrolment.operationId}`)
		}
		else alert('Need a submitted enrolment first')
	}

	/*
	const toggleLogin = async () => {
		let newUserData: UserData

		if (userData.user) {
			newUserData = Object.assign({}, userData, { user: undefined })
		}
		else {
			newUserData = Object.assign({}, userData, { user: defaultUser })
		}
		setUserData(newUserData)
	}
	*/

	return (
		<>
			<FrontEnd language={language} constants={mockConstants} onLanguageChange={setLanguage} externalRoutes={defaultExternalRoutes} userData={mockUserData} api={mockApi} />
			<DemoControlPanel>
				<Button intent='secondary' text='Create Sample Business' onClick={addBusiness} />
				<Button intent='secondary' text='Create Sample Application' onClick={addApplication} />
			</DemoControlPanel>
		</>
	)
}

export default App
