import { Params } from 'react-router-dom'
import { FormData } from '@netsyde/react-forms'

import { Language, Enrolment, Application, User } from 'portal2-models'
import { entityIdParam, applicationQueryParams } from './routes'
import { FrontEndContextType } from './FrontEnd'

export interface UserData {
	enrolments: Array<FormData<Enrolment>>
	applications: Array<FormData<Application>>
	user?: User
}

export interface EntityData<EntityT> {
	entity: FormData<EntityT> | undefined
}

export type EntityParams = typeof entityIdParam | typeof applicationQueryParams.applicationTypeId | typeof applicationQueryParams.operationId

export interface EntityRouteArg {
	params: Params<EntityParams>
	request: Request
}

// Loading page only requires language resources
export interface LocalizedProps {
	language: Language
}

export interface FrontEndPageProps {
	context: FrontEndContextType
}

// entity pages also need to know if we're creating a new application or enrolment
export interface EntityPageProps extends FrontEndPageProps {
	isNew?: boolean
	isReadonly?: boolean
}

export interface ExternalRoutes {
	portal: string
	login: string
	logout: string
	profile: string
}

export const defaultExternalRoutes: ExternalRoutes = {
	portal: 'https://osciaportal.org/',
	login: 'https://osciaportal.org/Identity/Account/Login',
	logout: 'https://osciaportal.org/Identity/Account/Logout',
	profile: 'https://osciaportal.org/Identity/Account/Manage'
}
